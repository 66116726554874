<template>
    <b-card>
        <b-tabs>
            <b-tab title="Pengaturan Bonus Subscription">
                <div v-for="subscriptions, indexSubs in mainSubscription" :key="indexSubs" class="my-2">
                    <h2 class="text-capitalize">{{ convertKeyMainSubscription[indexSubs] }}</h2>
                    <b-row v-for="(item, indexForm) in subscriptions" :key="indexForm" class="mb-1">
                        <b-col md="8" lg="6">
                            <label :for="indexSubs">{{ item.name }}</label>
                            <b-input-group prepend="Rp">
                                <cleave id="number" v-model="formPayload.main_subscription[indexSubs][indexForm].value"
                                    class="form-control" :raw="false" :options="customDelimiter" />
                            </b-input-group>
                        </b-col>
                    </b-row>
                </div>
                <b-button variant="primary" @click="saveData('main_subscription')">Simpan</b-button>
            </b-tab>
            <b-tab title="Pengaturan Bonus Penambahan User">
                <div v-for="subscriptions, indexSubs in userSubscription" :key="indexSubs" class="my-2">
                    <h2 class="text-capitalize">{{ convertKeyMainSubscription[indexSubs] }}</h2>
                    <b-row class="mb-1">
                        <b-col md="8" lg="6" v-for="(item, indexForm) in subscriptions" :key="indexForm" class="mb-1">
                            <label :for="indexSubs">{{ item.name }}</label>
                            <b-input-group append="%">
                                <cleave :id="indexSubs"
                                    v-model="formPayload.user_subscription[indexSubs][indexForm].value"
                                    class="form-control" :raw="false" :options="customDelimiter" />
                            </b-input-group>
                        </b-col>
                    </b-row>
                </div>
                <b-button variant="primary" @click="saveData('user_subscription')">Simpan</b-button>
            </b-tab>
        </b-tabs>
    </b-card>
</template>

<script>
import { BTabs, BTab, BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BFormGroup, BCardText, BCard, BRow, BCol, BButton, } from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'

import { successNotification, errorNotification } from '@/auth/utils'

export default {
    components: {
        BTabs,
        BCardText,
        BTab,
        BInputGroup,
        BFormInput,
        BInputGroupPrepend,
        BInputGroupAppend,
        BFormGroup,
        BCard,
        BRow,
        BCol,
        Cleave,
        BButton,
    },
    setup(props) {
        return {
            successNotification,
            errorNotification,
        }
    },
    data() {
        return {
            mainSubscription: Object,
            userSubscription: Object,
            convertKeyMainSubscription: {
                first_year: 'Tahun Pertama',
                next_year: 'Tahun Selanjutnya'
            },
            customDelimiter: {
                delimiters: ['.'],
                numeral: true,
                numeralThousandsGroupStyle: 'thousand',
                numeralDecimalMark: ',',
                delimiter: '.'
            },
            formPayload: Object
        }
    },
    watch: {
    },
    mounted() {
        this.getData()
    },
    methods: {
        saveData(type) {
            const form = this.preparePayload(type)

            this.$http.post('/super-admin/subscriptions/setting-update', form)
                .then((result) => {
                    this.getData()
                    successNotification(this, 'Success', 'Data berhasil diubah.')
                }).catch((err) => {
                    console.log(err);
                });
        },
        preparePayload(type_payload) {
            const form = new FormData()

            const settings = Object.values(this.formPayload[type_payload]).flatMap(subscriptionGroup =>
                subscriptionGroup.map(({ id, value, value_type, column }) => {
                    const processedValue = type_payload === 'main_subscription'
                        ? parseFloat(value.replace(/\./g, ''))
                        : parseFloat(value);

                    return { id, value: processedValue, value_type, column };
                })
            );

            settings.forEach((setting, index) => {
                Object.entries(setting).forEach(([key, value]) => {
                    form.append(`settings[${index}][${key}]`, value);
                });
            });

            return form
        },
        getData() {
            this.$http.get('/super-admin/subscriptions/setting')
                .then(response => {
                    const { main_subscription, user_subscription } = response.data.data
                    this.mainSubscription = main_subscription
                    this.userSubscription = user_subscription

                    this.formPayload.main_subscription = this.mainSubscription
                    this.formPayload.user_subscription = this.userSubscription
                }).catch((error) => {
                    console.log(error);
                })
        },
    }
}
</script>